import Rails from "@rails/ujs";

export function submitOnChange() {
  document.addEventListener("change", (event: Event) => {
    const target = event.target;

    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    // only continue if the target is set up to submit on change
    if (!target.dataset.submitOnChange) {
      return;
    }

    // take over click event
    event.preventDefault();

    // get the form
    const form = target.form;
    if (!(form instanceof HTMLFormElement)) {
      throw new Error("Could not find form for input element");
    }

    // submit the form
    Rails.fire(form, "submit");
  });
}
