import { Controller } from "stimulus";
import { Auth0Lock } from "auth0-lock";

const LOGO_URL =
  "https://boswell-hub-public-prd01.s3.amazonaws.com/images/logo.svg";
const PRIMARY_COLOR = "#698dd1";

export default class extends Controller {
  declare lock: typeof Auth0Lock | null;

  connect() {
    const container = this.element;
    if (!(container instanceof HTMLElement)) {
      throw new Error(
        "Could not find HTML element for mounted authentication controller"
      );
    }

    this.lock = this.buildLock(container);
    this.setupLockCallbacks(this.lock);

    const state = this.getState(container);
    this.lock.show({
      auth: {
        params: {
          state: state,
        },
      },
    });
  }

  setupLockCallbacks(lock: typeof Auth0Lock): void {
    let accessToken = null;
    let profile = null;

    lock.on("authenticated", function (authResult: AuthResult) {
      lock.getUserInfo(
        authResult.accessToken,
        function (
          error: auth0.Auth0Error,
          profileResult: auth0.Auth0UserProfile
        ) {
          if (error) {
            // Handle error
            return;
          }

          accessToken = authResult.accessToken;
          profile = profileResult;
        }
      );
    });
  }

  disconnect() {
    this.lock = null;
  }

  buildLock(container: HTMLElement): typeof Auth0Lock {
    let options: any = {
      auth: {
        redirectUrl: this.getRedirectUrl(container),
      },
      configurationBaseUrl: "https://cdn.us.auth0.com",
      closable: false,
      allowPasswordAutocomplete: true,
      theme: {
        logo: LOGO_URL,
        primaryColor: PRIMARY_COLOR,
      },
      languageDictionary: { title: "" },
      allowLogin: this.allowLogin(container),
      allowSignup: this.allowSignup(container),
    };

    const lockContainerId = this.getLockContainerId(container);
    if (lockContainerId) {
      options.container = lockContainerId;
    }

    return new Auth0Lock(
      this.getClientId(container),
      this.getDomain(container),
      options
    );
  }

  getState(container: HTMLElement): string {
    const state = container.dataset.state;
    if (!state) {
      throw new Error("State is not set");
    }
    if (state.trim().length == 0) {
      throw new Error("State is empty");
    }

    return state;
  }

  getLockContainerId(container: HTMLElement): string | null {
    return container.dataset.lockContainerId ?? null;
  }

  getRedirectUrl(container: HTMLElement): string {
    const redirectUrl = container.dataset.redirectUrl;
    if (!redirectUrl) {
      throw new Error("Redirect URL not set");
    }
    if (redirectUrl.trim().length == 0) {
      throw new Error("Redirect URL is empty");
    }

    return redirectUrl;
  }

  getClientId(container: HTMLElement): string {
    const clientId = container.dataset.clientId;
    if (!clientId) {
      throw new Error("Authentication client ID not set");
    }
    if (clientId.trim().length == 0) {
      throw new Error("Authentication client ID is empty");
    }

    return clientId;
  }

  getDomain(container: HTMLElement): string {
    const domain = container.dataset.domain;
    if (!domain) {
      throw new Error("Authentication domain not set");
    }
    if (domain.trim().length == 0) {
      throw new Error("Authentication domain is empty");
    }

    return domain;
  }

  allowLogin(container: HTMLElement): boolean {
    return !this.signup(container);
  }

  allowSignup(container: HTMLElement): boolean {
    return this.signup(container);
  }

  signup(container: HTMLElement): boolean {
    if (container.dataset.signup) {
      return true;
    } else {
      return false;
    }
  }
}
