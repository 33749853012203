const MODAL_CLOSE_BUTTON_CLASS = "modal-close-button";
const MODAL_CLASS = "modal";
const MODAL_ACTIVE_CLASS = "active";

export function modalCloseButtonCallback() {
  document.addEventListener("click", (event: Event) => {
    const target = event.target;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    // only proceed if modal close button was clicked
    if (!target.classList.contains(MODAL_CLOSE_BUTTON_CLASS)) {
      return;
    }

    // take over the click event
    event.preventDefault();

    // get the modal element
    const modal = target.closest(`.${MODAL_CLASS}`);
    if (!modal || !(modal instanceof HTMLElement)) {
      throw new Error(
        "Clicked the modal close button but could not find the modal"
      );
    }

    // close the modal
    modal.classList.remove(MODAL_ACTIVE_CLASS);
  });
}
