import { Controller } from "stimulus";

const CLASS_HIDDEN = "d-none";

export default class extends Controller {
  static targets = [
    "addressFieldGroup",
    "ageGroupFieldGroup",
    "showAddressLink",
    "dateOfBirth",
    "location",
    "street1",
    "city",
    "state",
    "zip_code",
    "latitude",
    "longitude",
  ];

  declare _autocomplete: google.maps.places.Autocomplete;

  declare ageGroupFieldGroupTarget: HTMLInputElement;
  declare addressFieldGroupTarget: HTMLInputElement;
  declare showAddressLink: HTMLInputElement;
  declare dateOfBirthTarget: HTMLInputElement;
  declare locationTarget: HTMLInputElement;
  declare street1Target: HTMLInputElement;
  declare cityTarget: HTMLInputElement;
  declare stateTarget: HTMLSelectElement;
  declare zip_codeTarget: HTMLInputElement;
  declare latitudeTarget: HTMLInputElement;
  declare longitudeTarget: HTMLInputElement;

  connect() {
    if (typeof google != "undefined") {
      this.initializeGooglePlaces();
    }
  }

  autoCompletePlaces() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(
        this.locationTarget
      );

      this._autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
      ]);
      this._autocomplete.addListener(
        "place_changed",
        this.locationChanged.bind(this)
      );
    }
    return this._autocomplete;
  }

  initializeGooglePlaces() {
    this.autoCompletePlaces();
  }

  fillInForm(addressComponents: any, latitude: any, longitude: any) {
    let street1 = "";
    let zipcode = "";

    this.latitudeTarget.value = latitude;
    this.longitudeTarget.value = longitude;

    for (const component of addressComponents as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          street1 = `${component.long_name} ${street1}`;
          break;
        }

        case "route": {
          street1 += component.short_name;
          break;
        }

        case "postal_code": {
          this.zip_codeTarget.value = `${component.short_name}`;
          break;
        }

        case "locality": {
          this.cityTarget.value = component.long_name;
          break;
        }

        case "administrative_area_level_1": {
          this.stateTarget.value = component.long_name;
          break;
        }
      }

      this.street1Target.value = street1;
    }
  }

  locationChanged() {
    let place = this.autoCompletePlaces().getPlace();

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("Select a location from the list");
      return;
    }

    this.fillInForm(
      place.address_components,
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  }

  preventSubmit(e: KeyboardEvent) {
    if (e.key == "Enter") {
      e.preventDefault();
    }
  }

  showAddressFields(event: Event) {
    event.preventDefault();
    this.addressFieldGroupTarget.classList.toggle("d-none");
  }

  toggleAgeGroup(_event: Event) {
    if (this.dateOfBirthTarget.value != "") {
      this.ageGroupFieldGroupTarget.classList.add(CLASS_HIDDEN);
    } else {
      this.ageGroupFieldGroupTarget.classList.remove(CLASS_HIDDEN);
    }
  }
}
