import { Controller } from "stimulus";
import debounce from "lodash.debounce";
import Rails from "@rails/ujs";

const DEBOUNCE_MS = 300;

export default class extends Controller {
  initialize() {
    this.type = debounce(this.type, DEBOUNCE_MS).bind(this);
  }

  type(_event: Event) {
    const form = this.element;
    if (!(form instanceof HTMLFormElement)) {
      throw new Error("Could not find the form element");
    }

    Rails.fire(form, "submit");
  }
}
