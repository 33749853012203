import { Controller } from "stimulus";
import Rails from "@rails/ujs";

const ORDER_ATTR_NAME = "data-order";
export default class extends Controller {
  static targets = ["form", "idInput", "newOrderInput"];
  declare formTarget: HTMLFormElement;
  declare idInputTarget: HTMLFormElement;
  declare newOrderInputTarget: HTMLFormElement;

  static classes = ["dragTargetIndicatorAbove", "dragTargetIndicatorBelow"];
  declare dragTargetIndicatorAboveClass: string;
  declare dragTargetIndicatorBelowClass: string;

  declare draggingElement: HTMLElement | null;

  connect() {
    this.resetState();
  }

  disconnect() {
    this.resetState();
  }

  dragStart(event: DragEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    // track the dragging element
    this.draggingElement = event.target;
  }

  dragEnd(_event: DragEvent): void {
    this.resetState();
  }

  dragEnter(event: DragEvent): void {
    // must prevent event default on both dragenter and dragover to make the element a valid drop target
    event.preventDefault();

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    if (!(this.draggingElement instanceof HTMLElement)) {
      throw new Error(
        "Dragging element property was not set on the controller"
      );
    }

    // drag target and source are the same
    if (event.target === this.draggingElement) {
      return;
    }

    const sourceOrder = this.getOrderValue(this.draggingElement);
    const targetOrder = this.getOrderValue(event.target);

    let dragTargetClass = null;

    if (sourceOrder < targetOrder) {
      // dragging down
      dragTargetClass = this.dragTargetIndicatorBelowClass;
    } else {
      // dragging up
      dragTargetClass = this.dragTargetIndicatorAboveClass;
    }

    event.target.classList.add(dragTargetClass);
  }

  dragLeave(event: DragEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    this.hideDragTargetIndicator(event.target);
  }

  dragOver(event: DragEvent): void {
    // must prevent event default on both dragenter and dragover to make the element a valid drop target
    event.preventDefault();
  }

  drop(event: DragEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    if (!(this.draggingElement instanceof HTMLElement)) {
      throw new Error(
        "Dragging element property was not set on the controller"
      );
    }

    const id = this.draggingElement.dataset.id;
    if (!id || id.trim().length == 0) {
      throw new Error("ID for field option to reorder was not set");
    }

    const newOrder = event.target.dataset.order;
    if (!newOrder || newOrder.trim().length == 0) {
      throw new Error("Order for field option was not set");
    }

    // set form input values
    this.idInputTarget.value = id;
    this.newOrderInputTarget.value = newOrder;

    // hide drag target indicator
    this.hideDragTargetIndicator(event.target);

    // submit form
    Rails.fire(this.formTarget, "submit");
  }

  private hideDragTargetIndicator(dragTargetElement: HTMLElement): void {
    dragTargetElement.classList.remove(this.dragTargetIndicatorAboveClass);
    dragTargetElement.classList.remove(this.dragTargetIndicatorBelowClass);
  }

  private getOrderValue(fieldOptionElement: HTMLElement): number {
    const attr = fieldOptionElement.getAttribute(ORDER_ATTR_NAME);
    if (!attr || attr.trim().length == 0) {
      throw new Error("Could not find order attribute for drag target");
    }

    return parseInt(attr);
  }

  private resetState(): void {
    this.draggingElement = null;
  }
}
