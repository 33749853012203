import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "template"];

  // TypeScript with Stimulus 2.0 must declare properties
  // https://github.com/stimulusjs/stimulus/issues/303#issuecomment-653630360
  declare templateTarget: HTMLElement;
  declare add_itemTarget: HTMLElement;

  add_reason(event: Event) {
    event.preventDefault();
    if (!this.templateTarget) {
      throw new Error(
        "Could not find template target for the add reason button"
      );
    }

    // content with current date
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf().toString()
    );

    // insert a new reason
    if (!this.add_itemTarget) {
      throw new Error("Could not find the add item target");
    }
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_reason(event: Event) {
    // take over the click event
    event.preventDefault();

    // remove reason button
    const removeReasonButton = event.currentTarget;

    if (!(removeReasonButton instanceof HTMLElement)) {
      throw new Error("Could not find the remove reason button");
    }

    // nested fields item
    let item = removeReasonButton.closest(".nested-fields");
    if (!(item instanceof HTMLElement)) {
      throw new Error("Could not find the reason item nested fields container");
    }

    // destroy hidden fields for rails nested attributes
    let destroyButton = item.querySelector("input[name*='_destroy']");
    if (!(destroyButton instanceof HTMLInputElement)) {
      throw new Error("Could not find the remove reason button");
    }

    destroyButton.value = "1";

    // hide the reason
    item.style.display = "none";
  }
}
