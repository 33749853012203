//@ts-ignore
import ReactRailsUJS from 'react_ujs';
import wait from "fork-ts-checker-webpack-plugin/lib/utils/async/wait";

// const originalMount = ReactRailsUJS.mountComponents;
//
// ReactRailsUJS.mountComponents = function() {
//   originalMount.apply(this, arguments);
//
//   // Dispatch a custom event when mounting is done
//   const event = new CustomEvent('reactComponentsMounted');
//   document.dispatchEvent(event);
// };

const unrenderComponents = async () => {
  ReactRailsUJS.unmountComponents()
}

/**
 * This resolves the issue where on Rails view re-renders, e.g. when a form is submitted, the React components are not
 * automatically re-rendered. This forces them to render, so they actually show.
 */

const renderComponents = async () => {

  ReactRailsUJS.mountComponents()
  // await wait(50)
  // This is a custom event that is dispatched when the React components are re-rendered. This is used to trigger
  // custom behavior that shouldn't happen on a page refresh, but should happen when the React components are
  // manually re-rendered, e.g. when failing server-side validation.
}

// @ts-ignore
// TODO: I would really love this to not be a shitty global, but have not solved how to include it in .js.erb files with
//       a typical import / require / //require / <%= javascript_include %> / etc.
window.renderComponents = renderComponents
// @ts-ignore
window.unrenderComponents = unrenderComponents