const FAB_BUTTON_CLASS = "fab-btn";
const FAB_CONTAINER_ID = "fab-container";
const FAB_DRAWER_ID = "fab-drawer";
const FAB_DRAWER_ACTIVE_CLASS = "active";

export function fabCallback() {
  document.addEventListener("click", (event: Event) => {
    const target = event.target;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    // find fab button
    const fabButton = target.closest(`.${FAB_BUTTON_CLASS}`);
    if (!fabButton) {
      return;
    }

    // get fab container
    const container = fabButton.closest(`#${FAB_CONTAINER_ID}`);
    if (!container) {
      throw new Error("Could not find FAB container");
    }

    // get fab drawer
    const drawer = container.querySelector(`#${FAB_DRAWER_ID}`);
    if (!drawer) {
      return;
    }

    // we found a drawer so take over event
    event.preventDefault();

    if (!(drawer instanceof HTMLElement)) {
      throw new Error("Could not find FAB drawer");
    }

    toggleDrawer(drawer);
  });
}

function toggleDrawer(drawer: HTMLElement) {
  if (drawer.classList.contains(FAB_DRAWER_ACTIVE_CLASS)) {
    drawer.classList.remove(FAB_DRAWER_ACTIVE_CLASS);
  } else {
    drawer.classList.add(FAB_DRAWER_ACTIVE_CLASS);
  }
}
