import { Controller } from "stimulus";
import throttle from "lodash.throttle";

const DEBOUNCE_MS = 200;
const CLASS_SCROLLING_DOWN = "scrollingDown";

export default class extends Controller {
  static targets = ["label"];

  declare labelTarget: HTMLElement;

  // scroll starts at top of the screen which is always 0
  lastScroll: number = 0;

  initialize() {
    // don't flood the browser with scroll events
    this.scroll = throttle(this.scroll, DEBOUNCE_MS).bind(this);
  }

  scroll(_event: Event) {
    // get current scroll position
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;

    // compare to last scroll position to determine scroll direction
    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      // scrolling down
      this.labelTarget.classList.add(CLASS_SCROLLING_DOWN);
    } else {
      // scrolling up
      this.labelTarget.classList.remove(CLASS_SCROLLING_DOWN);
    }

    // update last scroll position for future comparisons
    this.lastScroll = currentScroll;
  }
}
