import { Controller } from "stimulus";

export default class extends Controller {
  private _domIdToShow: string = "";

  initialize() {
    this._domIdToShow = (this.element as HTMLElement).dataset
      .showDomIdOnDismiss as string;
  }

  dismiss(_event: Event) {
    _event.preventDefault();
    const form = document.getElementById(this._domIdToShow);
    if (form instanceof HTMLElement) {
      this.element.classList.add("d-none");
      form.classList.remove("d-none");
    }
  }
}
