// Place your actual application logic in a relevant structure within app/javascript
// and only use these pack files to reference that code so it'll be compiled.

// stylesheets imported through webpack
import "../stylesheets/application.scss";

// images imported through webpack
import "../images";

console.log("Application file loaded")

// Rails UJS
import Rails from "@rails/ujs";
Rails.start();

// Turbolinks
require("turbolinks").start();

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// We're not using WebSockets channels with Rails ActionCable right now,
// so these are commented out
// require("@rails/activestorage").start();
// require("channels");

// Stimulus imports
import "@stimulus/polyfills";
import {Application} from "stimulus";
import {definitionsFromContext} from "stimulus/webpack-helpers";

import Appsignal from "@appsignal/javascript";
// TODO: Depends on stimulus 1.0, while 2.0 is used, generating a conflict
// import {installErrorHandler} from "@appsignal/stimulus";

declare global {
  interface Window {
    appsignalApiKey: string;
  }
}

const appsignal = new Appsignal({
  key: window.appsignalApiKey,
});

// Stimulus setup through webpack integration
const application = Application.start();
// installErrorHandler(appsignal, application);
const context = require.context("../controllers", true, /\.ts$/);
application.load(definitionsFromContext(context));

// application logic
import {addHouseholdMemberCallback} from "../add_household_member";
import {fabCallback} from "../fab";
import {modalCloseButtonCallback} from "../modal";
import {submitOnChange} from "../submit_on_change";

function appSetup() {
  submitOnChange();
  addHouseholdMemberCallback();
  fabCallback();
  modalCloseButtonCallback();
}

appSetup();

// mobile drag and drop polyfill, comes last so it doesn't block
// more important code along the app's critical path
import "../polyfills/DragDropTouch";
import HelloWorld from "components/HelloWorld";

declare global {
  interface Window {
    initMap: Function;
  }
}

window.initMap = function (...args: any[]) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  window.dispatchEvent(event);
};

import 'react-renderer'
import React from "react";

// Load react components
const componentRequireContext = require.context("../components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents()

document.addEventListener("turbolinks:load", () => {
  ReactRailsUJS.unmountComponents()
  ReactRailsUJS.mountComponents();
});