import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  declare formTarget: HTMLFormElement;

  connect() {
    this.formTarget.submit();
  }
}
