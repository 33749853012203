import { Controller } from "stimulus";

const TOGGLE_LABEL_AGE = "Use age instead";
const TOGGLE_LABEL_DOB = "Use date of birth instead";

export default class extends Controller {
  static targets = [
    "dobFormGroup",
    "ageFormGroup",
    "toggleLabel",
    "dobInput",
    "ageInput",
  ];

  declare dobFormGroupTarget: HTMLElement;
  declare ageFormGroupTarget: HTMLElement;
  declare ageFormGroupTargets: HTMLElement[];
  declare hasAgeFormGroupTarget: boolean;
  declare toggleLabelTarget: HTMLElement;
  declare dobInputTarget: HTMLInputElement;
  declare ageInputTarget: HTMLInputElement;

  static classes = ["hidden"];

  declare hiddenClass: string;

  connect(): void {
    this.updateToggleLabel();
  }

  toggle(event: MouseEvent): void {
    // take over click event
    event.preventDefault();

    this.toggleFieldsVisbility();
    this.clearHiddenInputValue();
    this.updateToggleLabel();
  }

  private toggleFieldsVisbility(): void {
    this.dobFormGroupTarget.classList.toggle(this.hiddenClass);
    this.ageFormGroupTarget.classList.toggle(this.hiddenClass);
  }

  private clearHiddenInputValue(): void {
    if (this.dobFormGroupTarget.classList.contains(this.hiddenClass)) {
      this.dobInputTarget.value = "";
    } else if (this.ageFormGroupTarget.classList.contains(this.hiddenClass)) {
      this.ageInputTarget.value = "";
    }
  }

  private updateToggleLabel(): void {
    //initialize toggle label
    if (!this.hasAgeFormGroupTarget) {
      return;
    }

    if (this.ageFormGroupTarget.classList.contains(this.hiddenClass)) {
      this.toggleLabelTarget.textContent = TOGGLE_LABEL_AGE;
    } else {
      this.toggleLabelTarget.textContent = TOGGLE_LABEL_DOB;
    }
  }
}
