export function addHouseholdMemberCallback() {
  document.addEventListener("click", (event: Event) => {
    const target = event.target;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    // find button
    const addHouseholdMemberButton = target.closest(
      "[data-add-household-member]"
    );
    if (!addHouseholdMemberButton) {
      return;
    }

    // take over click event
    event.preventDefault();

    // href is used to look up modal element
    const href = addHouseholdMemberButton.getAttribute("href");
    if (!href) {
      throw new Error(
        "Could not find href for add household member modal button"
      );
    }

    // get modal
    const id = href.replace("#", "");
    const modal = document.getElementById(id);
    if (!modal) {
      throw new Error(`Could not find add household member modal for id ${id}`);
    }

    // activate modal
    modal.classList.add("active");
  });
}
