import { Controller } from "stimulus";
import debounce from "lodash.debounce";
import Rails from "@rails/ujs";

const DEBOUNCE_MS = 300;

export default class extends Controller {
  static targets = ["client_query"];

  declare client_queryTarget: HTMLInputElement;

  initialize() {
    this.type = debounce(this.type, DEBOUNCE_MS).bind(this);
  }

  type(_event: Event) {
    const form = this.element;
    if (!(form instanceof HTMLFormElement)) {
      throw new Error("Could not find the form element");
    }

    Rails.fire(form, "submit");
  }

  // Will clear the client query input field
  // NOTE: This can clear the input field but it's only for reference.
  // Rails Households::Controller#clear_search_query has changes that clear the input field.
  connect() {
    if (!(this.client_queryTarget instanceof HTMLInputElement)) {
      throw new Error("Could not find the input element");
    }
    this.client_queryTarget.value = ""
  }

  disconnect() {
    if (!(this.client_queryTarget instanceof HTMLInputElement)) {
      throw new Error("Could not find the input element");
    }
    this.client_queryTarget.value = ""
  }
}
