import { Controller } from "stimulus";
const Turbolinks = require("turbolinks");

const PAGE_RELOAD_DELAY_MS = 200;

export default class extends Controller {
  static values = { path: String };

  declare pathValue: String;
  declare timeout: NodeJS.Timeout;

  connect() {
    this.timeout = setTimeout(() => {
      Turbolinks.visit(this.pathValue, { action: "replace" });
    }, PAGE_RELOAD_DELAY_MS);
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
