import { Controller } from "stimulus";
import debounce from "lodash.debounce";
import Rails from "@rails/ujs";

const TYPE_DEBOUNCE_MS = 300;
const FORM_AUTO_SUBMIT_DELAY_MS = 5000;
export default class extends Controller {
  declare formAutoSubmitTimeout: NodeJS.Timeout;

  initialize() {
    this.type = debounce(this.type, TYPE_DEBOUNCE_MS).bind(this);
  }

  connect() {
    this.enqueueAutoSubmit();
  }

  disconnect() {
    if (this.formAutoSubmitTimeout) {
      clearTimeout(this.formAutoSubmitTimeout);
    }
  }

  type(_event: Event) {
    this.submitForm();
  }

  private enqueueAutoSubmit() {
    this.formAutoSubmitTimeout = setTimeout(() => {
      // submit form then queue up the next auto submission
      this.submitForm();
      this.enqueueAutoSubmit();
    }, FORM_AUTO_SUBMIT_DELAY_MS);
  }

  private submitForm(): void {
    Rails.fire(this.formElement(), "submit");
  }

  private formElement(): HTMLFormElement {
    const form = this.element;
    if (!(form instanceof HTMLFormElement)) {
      throw new Error("Could not find the form element");
    }

    return form;
  }
}
